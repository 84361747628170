export const COMPONENTS = {
  CREDIT_CHIP: 'Credit Chip',
  WICKED_FANG: 'Wicked Fang',
  POLYMER_EMULSION: 'Polymer Emulsion',
  DIGITAL_CIRCUIT: 'Digital Circuit',
  CHIPPED_FANG: 'Chipped Fang',
  TORN_LEATHER: 'Torn Leather',
  THICKENED_HIDE: 'Thickened Hide',
  CIETH_TEAR: "Cie'th Tear",
  TEAR_OF_FRUSTRATION: 'Tear of Frustration',
  BEGRIMED_CLAW: 'Begrimed Claw',
  BESTIAL_CLAW: 'Bestial Claw',
  PARAFFIN_OIL: 'Paraffin Oil',
  SPARK_PLUG: 'Spark Plug',
  PASSIVE_DETECTOR: 'Passive Detector',
  SHATTERED_BONE: 'Shattered Bone',
  STURDY_BONE: 'Sturdy Bone',
  ELECTROLYTIC_CAPACITOR: 'Electrolytic Capacitor',
  CERAMIC_ARMOR: 'Ceramic Armor',
  BOMB_ASHES: 'Bomb Ashes',
  BOMB_FRAGMENT: 'Bomb Fragment',
  GUMMY_OIL: 'Gummy Oil',
  FRAGRANT_OIL: 'Fragrant Oil',
  TURBOJET: 'Turbojet',
  SUPERCONDUCTOR: 'Superconductor',
  INCENTIVE_CHIP: 'Incentive Chip',
  ABOMINABLE_WING: 'Abominable Wing',
  URANINITE: 'Uranitite',
  GYROSCOPE: 'Gyroscope',
  PIEZOELECTRIC_ELEMENT: 'Piezoelectric Element',
  SILICONE_OIL: 'Silicone Oil',
  MURKY_OOZE: 'Murky Ooze',
  VIBRANT_OOZE: 'Vibrant Ooze',
  SEGMENTED_CARAPACE: 'Segmented Carapace',
  IRON_SHELL: 'Iron Shell',
  MOLTED_TAIL: 'Molted Tail',
  BARBED_TAIL: 'Barbed Tail',
  COBALITE: 'Cobalite',
  RADIAL_BEARING: 'Radial Bearing',
  GARGATUAN_CLAW: 'Gargatuan Claw',
  MONSTROUS_FANG: 'Monstrous Fang',
  WONDER_GEL: 'Wonder Gel',
  ANALOG_CIRCUIT: 'Analog Circuit',
  BUTTERFLY_VALVE: 'Butterfly Valve',
  SOLENOID: 'Solenoid',
  PARTICLE_ACCELERATOR: 'Particle Accelerator',
  REGENERATING_CARAPACE: 'Regenerating Carapace',
  ENTRANCING_TAIL: 'Entrancing Tail',
  ANCIENT_BONE: 'Ancient Bone',
  MOONBLOSSOM_SEED: 'Moonblossom Seed',
  TEAR_OF_WOE: 'Tear of Woe',
  TEAR_OF_REMORSE: 'Tear of Remorse',
  TESLA_TURBINE: 'Tesla Turbine',
  CHOBHAM_ARMOR: 'Chobham Armor',
  GOLD_DUST: 'Gold Dust',
  SCARLETITE: 'Scarletite',
  PERFUME: 'Perfume',
  DUSKLIGHT_DEW: 'Dusklight Dew',
  STARBLOSSOM_SEED: 'Starblossom Seed',
}

export const COMPONENTS_BY_CHAPTER = [
  [
    COMPONENTS.CHIPPED_FANG,
    COMPONENTS.WICKED_FANG,
    COMPONENTS.CREDIT_CHIP,
    COMPONENTS.POLYMER_EMULSION,
    COMPONENTS.DIGITAL_CIRCUIT,
  ],
  [
    COMPONENTS.TORN_LEATHER,
    COMPONENTS.THICKENED_HIDE,
    COMPONENTS.CHIPPED_FANG,
    COMPONENTS.WICKED_FANG,
    COMPONENTS.CIETH_TEAR,
    COMPONENTS.TEAR_OF_FRUSTRATION,
  ],
  [
    COMPONENTS.CIETH_TEAR,
    COMPONENTS.TEAR_OF_FRUSTRATION,
    COMPONENTS.DIGITAL_CIRCUIT,
    COMPONENTS.BEGRIMED_CLAW,
    COMPONENTS.BESTIAL_CLAW,
    COMPONENTS.CHIPPED_FANG,
    COMPONENTS.WICKED_FANG,
  ],
  [
    COMPONENTS.CHIPPED_FANG,
    COMPONENTS.WICKED_FANG,
    COMPONENTS.PARAFFIN_OIL,
    COMPONENTS.DIGITAL_CIRCUIT,
    COMPONENTS.SPARK_PLUG,
    COMPONENTS.PASSIVE_DETECTOR,
    COMPONENTS.SHATTERED_BONE,
    COMPONENTS.STURDY_BONE,
    COMPONENTS.ELECTROLYTIC_CAPACITOR,
    COMPONENTS.CERAMIC_ARMOR,
    COMPONENTS.POLYMER_EMULSION,
    COMPONENTS.BOMB_ASHES,
    COMPONENTS.BOMB_FRAGMENT,
  ],
  [
    COMPONENTS.CHIPPED_FANG,
    COMPONENTS.WICKED_FANG,
    COMPONENTS.GUMMY_OIL,
    COMPONENTS.FRAGRANT_OIL,
    COMPONENTS.BEGRIMED_CLAW,
    COMPONENTS.TURBOJET,
    COMPONENTS.DIGITAL_CIRCUIT
  ],
  [
    COMPONENTS.POLYMER_EMULSION,
    COMPONENTS.WICKED_FANG,
    COMPONENTS.STURDY_BONE
  ],
  [
    COMPONENTS.DIGITAL_CIRCUIT,
    COMPONENTS.SUPERCONDUCTOR,
    COMPONENTS.CREDIT_CHIP,
    COMPONENTS.INCENTIVE_CHIP,
    COMPONENTS.TURBOJET,
    COMPONENTS.ABOMINABLE_WING,
    COMPONENTS.URANINITE,
  ],
  [
    COMPONENTS.GYROSCOPE,
    COMPONENTS.PIEZOELECTRIC_ELEMENT,
  ],
  [
    COMPONENTS.CREDIT_CHIP,
    COMPONENTS.INCENTIVE_CHIP,
    COMPONENTS.SILICONE_OIL,
    COMPONENTS.MURKY_OOZE,
    COMPONENTS.VIBRANT_OOZE,
    COMPONENTS.MOLTED_TAIL,
    COMPONENTS.BARBED_TAIL,
    COMPONENTS.SEGMENTED_CARAPACE,
    COMPONENTS.IRON_SHELL,
    COMPONENTS.COBALITE,
  ],
  [
    COMPONENTS.SPARK_PLUG,
    COMPONENTS.RADIAL_BEARING,
  ],
  [
    COMPONENTS.BEGRIMED_CLAW,
    COMPONENTS.GARGATUAN_CLAW,
    COMPONENTS.CHIPPED_FANG,
    COMPONENTS.MONSTROUS_FANG,
    COMPONENTS.MURKY_OOZE,
    COMPONENTS.WONDER_GEL,
    COMPONENTS.ANALOG_CIRCUIT,
    COMPONENTS.BUTTERFLY_VALVE,
    COMPONENTS.SOLENOID,
    COMPONENTS.PARTICLE_ACCELERATOR,
    COMPONENTS.SEGMENTED_CARAPACE,
    COMPONENTS.REGENERATING_CARAPACE,
    COMPONENTS.MOLTED_TAIL,
    COMPONENTS.ENTRANCING_TAIL,
    COMPONENTS.SHATTERED_BONE,
    COMPONENTS.ANCIENT_BONE,
    COMPONENTS.MOONBLOSSOM_SEED,
    COMPONENTS.CIETH_TEAR,
    COMPONENTS.TEAR_OF_WOE,
    COMPONENTS.SUPERCONDUCTOR,
    COMPONENTS.WICKED_FANG,
    COMPONENTS.STURDY_BONE,
    COMPONENTS.TEAR_OF_REMORSE,
    COMPONENTS.COBALITE,
  ],
  [
    COMPONENTS.TESLA_TURBINE,
    COMPONENTS.ELECTROLYTIC_CAPACITOR,
    COMPONENTS.CHOBHAM_ARMOR,
    COMPONENTS.CREDIT_CHIP,
    COMPONENTS.INCENTIVE_CHIP,
    COMPONENTS.BEGRIMED_CLAW,
    COMPONENTS.GARGATUAN_CLAW,
    COMPONENTS.PARTICLE_ACCELERATOR,
    COMPONENTS.GOLD_DUST,
    COMPONENTS.SCARLETITE,
    COMPONENTS.PERFUME,
    COMPONENTS.DUSKLIGHT_DEW,
  ],
  [
    COMPONENTS.MOONBLOSSOM_SEED,
    COMPONENTS.STARBLOSSOM_SEED,
    COMPONENTS.CIETH_TEAR,
    COMPONENTS.TEAR_OF_WOE,
  ]
]
