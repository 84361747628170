import { COMPONENTS } from './enums';

const itemGilValues = {
  [COMPONENTS.CREDIT_CHIP]: 500,
  [COMPONENTS.WICKED_FANG]: 40,
  [COMPONENTS.POLYMER_EMULSION]: 100,
  [COMPONENTS.DIGITAL_CIRCUIT]: 230,
  [COMPONENTS.CHIPPED_FANG]: 15,
  [COMPONENTS.TORN_LEATHER]: 15,
  [COMPONENTS.THICKENED_HIDE]: 40,
  [COMPONENTS.CIETH_TEAR]: 15,
  [COMPONENTS.TEAR_OF_FRUSTRATION]: 40,
  [COMPONENTS.BEGRIMED_CLAW]: 15,
  [COMPONENTS.BESTIAL_CLAW]: 40,
  [COMPONENTS.PARAFFIN_OIL]: 160,
  [COMPONENTS.SPARK_PLUG]: 160,
  [COMPONENTS.PASSIVE_DETECTOR]: 420,
  [COMPONENTS.SHATTERED_BONE]: 15,
  [COMPONENTS.STURDY_BONE]: 40,
  [COMPONENTS.ELECTROLYTIC_CAPACITOR]: 160,
  [COMPONENTS.CERAMIC_ARMOR]: 330,
  [COMPONENTS.BOMB_ASHES]: 90,
  [COMPONENTS.BOMB_FRAGMENT]: 215,
  [COMPONENTS.GUMMY_OIL]: 15,
  [COMPONENTS.FRAGRANT_OIL]: 40,
  [COMPONENTS.TURBOJET]: 420,
  [COMPONENTS.SUPERCONDUCTOR]: 420,
  [COMPONENTS.INCENTIVE_CHIP]: 2500,
  [COMPONENTS.ABOMINABLE_WING]: 75,
  [COMPONENTS.URANINITE]: 5000,
  [COMPONENTS.GYROSCOPE]: 330,
  [COMPONENTS.PIEZOELECTRIC_ELEMENT]: 420,
  [COMPONENTS.SILICONE_OIL]: 320,
  [COMPONENTS.MURKY_OOZE]: 15,
  [COMPONENTS.VIBRANT_OOZE]: 40,
  [COMPONENTS.SEGMENTED_CARAPACE]: 15,
  [COMPONENTS.IRON_SHELL]: 40,
  [COMPONENTS.MOLTED_TAIL]: 15,
  [COMPONENTS.BARBED_TAIL]: 40,
  [COMPONENTS.COBALITE]: 3000,
  [COMPONENTS.RADIAL_BEARING]: 320,
  [COMPONENTS.GARGATUAN_CLAW]: 75,
  [COMPONENTS.MONSTROUS_FANG]: 75,
  [COMPONENTS.WONDER_GEL]: 110,
  [COMPONENTS.ANALOG_CIRCUIT]: 90,
  [COMPONENTS.BUTTERFLY_VALVE]: 420,
  [COMPONENTS.SOLENOID]: 420,
  [COMPONENTS.PARTICLE_ACCELERATOR]: 5000,
  [COMPONENTS.REGENERATING_CARAPACE]: 110,
  [COMPONENTS.ENTRANCING_TAIL]: 110,
  [COMPONENTS.ANCIENT_BONE]: 110,
  [COMPONENTS.MOONBLOSSOM_SEED]: 6000,
  [COMPONENTS.TEAR_OF_REMORSE]: 75,
  [COMPONENTS.TEAR_OF_WOE]: 110,
  [COMPONENTS.TESLA_TURBINE]: 900,
  [COMPONENTS.CHOBHAM_ARMOR]: 500,
  [COMPONENTS.GOLD_DUST]: 15000,
  [COMPONENTS.SCARLETITE]: 7000,
  [COMPONENTS.PERFUME]: 12500,
  [COMPONENTS.DUSKLIGHT_DEW]: 850,
  [COMPONENTS.STARBLOSSOM_SEED]: 13000,
}

export const getComponentGilValue = item_name => itemGilValues[item_name]
